<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
const { currentWebsite } = useWebsite()
const { dispatchActionNewsletterEvent } = useGAActionEvents()
const { checkTokenReCAPTCHA } = useRecaptchaSite()
const { customer } = await useCustomer()
const { openNewsletterSidebar } = await useNewsletter()

const { privacyPolicy } = useRouteHelper()
const formData = ref({ email: '' })

const submitHandler = async () => {
  await checkTokenReCAPTCHA('newsletterFirstStep')
  openNewsletterSidebar(formData.value.email)
  dispatchActionNewsletterEvent(currentWebsite.value)
}

const customerEmail = computed(() => customer?.value?.email || false)

const isReadOnly = ref(false)
const isInputFocused = ref(false)

const handleFocus = () => {
  isInputFocused.value = true
}

onMounted(() => {
  if (customerEmail.value) {
    formData.value.email = customerEmail.value
    isReadOnly.value = true
  }
})
</script>

<template>
  <div class="gap-xs flex flex-col">
    
<FormKitLazyProvider config-file="true">
<h3 class="text-medium-6">
      {{ $ts('footer.firstBlock.newsletter') }}
    </h3>
    <FormKit
      id="newsletter-footer-form"
      v-model="formData"
      type="form"
      :actions="false"
      :incomplete-message="false"
      @submit="submitHandler"
    >
      <InputsTextFields
        id="newsletter-email"
        type="email"
        name="email"
        autocomplete="email"
        validation="required|email"
        :validation-messages="{
          required: $ts('generic.requiredEmail'),
          email: $ts('generic.invalidEmail'),
        }"
        :label="$ts('footer.firstBlock.email')"
        :readonly="isReadOnly"
        :confirm-text="$ts('newsletter.subscribe')"
        @confirm="submitHandler"
        @focus="handleFocus"
      />
    </FormKit>
    <p v-if="(!customer && isInputFocused) || customer" class="text-light-6">
      {{ $ts('newsletter.disclaimerSubscribe') }}
      <MoleculesLinkWrapper :to="privacyPolicy" anatomy="link">
        <template #label>
          {{ $ts('newsletter.privacyPolicy') }}
        </template>
      </MoleculesLinkWrapper>
    </p>
</FormKitLazyProvider>

  </div>
</template>
